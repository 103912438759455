
import ModalWrapper from '@/components/modals/ModalWrapper.vue';
import { defineComponent } from 'vue';
import BaseDropdown from "../dropdown/BaseDropdown.vue";
import Trigger from "../dropdown/Trigger.vue";
import DropdownOptions from "../dropdown/DropdownOptions.vue";
import vehicleModels from "@/services/vehicleModels";

export default defineComponent({
  name: 'ChangeCategoryModal',
  components: { ModalWrapper, DropdownOptions, BaseDropdown, Trigger },
  props: {
    checked: {
      type: Array
    }
  },
  data() {
    return {
      vehicleTypes: [] as any,
      pickedOption: null as any,
      modelCategories: {
        moto: 'Мотоциклы',
        light: 'Легковые',
        commerce: 'Лёгкие коммерческие',
        heavy: 'Грузовые и автобусы',
        agro: 'Тракторы и спецтехника',
        all_categories: 'Все категории'
      } as any
    }
  },
  computed: {
    getOptionsCategoryModel(): any {
      return this.vehicleTypes.map((type: any) => type.value)
    },
    getDefaultOptionDropdown(): any {
      if (this.$route.query.vehicleType) {
        const typeBrandByRouteQuery = String(this.$route.query.vehicleType).toLowerCase()
        return this.modelCategories[typeBrandByRouteQuery]
      }
    }
  },
  methods: {
    setOption(option: any) {
      this.pickedOption = option
    },
    async saveCategory() {
      if (this.checked) {
        await Promise.all(this.checked.map(async (model: any) => {
          const category = this.vehicleTypes.filter((type: any) => type.value === this.pickedOption)[0]
          const payload = {
            tecdocId: model.tecdocId,
            modelFull: model.modelFull,
            model: model.model,
            modelConstruction: model.modelConstruction,
            modCstTermNo: model.modCstTermNo,
            brandId: model.brandId,
            modelSort: model.modelSort,
            constructionYearFrom: model.constructionYearFrom,
            constructionYearTo: model.constructionYearTo,
            category: category.key,
          }
          // supplierId
          console.log('Model!!', model)

          await vehicleModels.updateVehicleModel(model.id, payload)
        }))

        this.$emit('closeChangeCategoryModal')
      }
    }
  },
  mounted() {
    this.vehicleTypes = this.$store.getters.getVehicleCategories
  }
})
