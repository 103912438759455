
import {defineComponent, computed} from 'vue';
import VehicleLockModal from '@/components/modals/VehicleLockModal.vue';
import {roles} from "@/services/roles/serviceRoles";
import ChangeCategoryModal from "@/components/modals/ChangeCategoryModal.vue";
import {MessageModalMutationTypes} from "@/store/modules/message-modal/mutation-types";
import {useRoute} from "vue-router";

export default defineComponent({
  name: 'HeaderVehicleInfo',
  components: { VehicleLockModal, ChangeCategoryModal },
  emits: ['changeBlockStatus', 'reload', 'selectAll'],
  props: {
    checked: {
      type: Array,
      required: true,
      default: () => null
    },
    checkedAll: {
      type: Boolean,
      default: () => false
    },
    query: {
      type: String,
      required: true,
      default: () => null
    },
    vehicleInfo: {
      type: String,
      required: true,
      default: () => null
    }
  },
  data: () => ({
    isShowModal: false,
    showChangeCategoryModal: false as boolean
  }),
  setup(props) {
    const route = useRoute()
    const isQueryActual = route.query.vehicleState === 'actual'
    const titleButtonBlocked = computed(() => (isQueryActual ? 'Заблокировать' : 'Разблокировать'))
    const titleButtonSelectAll = computed(() => (props.checkedAll ? 'Отменить все' : 'Выбрать все'))
    const titleInfoBlocked = computed(() => `Выберите позиции для ${isQueryActual ? 'блокировки' : 'разблокировки'} или перейдите к определенной марке`)

    return {
      titleButtonBlocked,
      titleButtonSelectAll,
      titleInfoBlocked,
      isQueryActual,
    }
  },
  computed: {
    isShowButtonChangeCategory():any {
      return this.vehicleInfo === 'models' && this.$route.query.vehicleType !== 'all_categories'
    }
  },
  methods: {
    openModalChangeCategory() {
      this.showChangeCategoryModal = true
      setTimeout(() => {
        let element = document.getElementById(`modal-change-category`)!
        element.style.display = 'flex'
      }, 0)
    },
    closeChangeCategoryModal() {
      this.showChangeCategoryModal = false
      this.$store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'SUCCESS')
      this.$emit('reload')
    },
    openModal() {
      this.isShowModal = true
      setTimeout(() => {
        let element = document.getElementById(`modal-lock-vehicle`)!
        element.style.display = 'flex'
      }, 0)
    },
    selectAll() {
      this.$emit('selectAll')
    },
    changeBlockStatus() {
      this.$emit('changeBlockStatus')
    },
    closeModal() {
      this.isShowModal = false
    }
  },
})
