
import { defineComponent } from 'vue';
import BaseIcon from "@/components/ui/BaseIcon.vue";
import BaseDropdown from "@/components/dropdown/BaseDropdown.vue";
import Trigger from "@/components/dropdown/Trigger.vue";
import DropdownOptions from "@/components/dropdown/DropdownOptions.vue";

export default defineComponent({
  name: 'ModelEditItem',
  data: () => ({
    dropdownValue: null as any,
    editMode: false,
    fieldValue: null,
    vehicleTypes: [] as any,
  }),
  components: {
    BaseIcon,
    BaseDropdown,
    Trigger,
    DropdownOptions
  },
  props: {
    type: {
      type: String,
      default: () => 'text'
    },
    index: {
      type: Number
    },
    currentModel: {
      type: Object,
      default: () => null
    },
    canEdit: {
      type: Boolean,
      default: () => null
    },
    fieldName: {
      type: String,
      default: () => null
    },
    currentField: {
      type: String,
      default: () => null
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  computed: {
    getOptionsCategoryModel(): any {
      return this.vehicleTypes.map((type: any) => type.value)
    },
    getFieldName() {
      return () => this.fieldName ? this.fieldName : ''
    },
    getCurrentChangedField() {
      return (key: any) => {
        if (key === 'category') {
          const categoryValue = this.vehicleTypes.filter((type: any) => type.key === this.currentModel[key])
          return categoryValue.length ? categoryValue[0].value : null
        }

        return key !== 'brand'
            ? this.currentModel[key]
            : null
      }
    }
  },
  methods: {
    cancelChangesField() {
      this.editMode = false
      const currentFieldValue = this.getCurrentChangedField(this.currentField)
      const isFieldYearsVehicle = this.currentField === 'constructionYearFrom' || this.currentField === 'constructionYearTo'

      this.changeValue(currentFieldValue, isFieldYearsVehicle)
    },
    setOption(option: any) {
      const categoryOption = this.vehicleTypes.find((type: any) => type.value === option);

      if (categoryOption) {
        this.dropdownValue = option
        this.changeValue(categoryOption.key.toUpperCase())
        return;
      }

      this.dropdownValue = option
      this.changeValue(option)
    },
    changeValue(value: string, isStrongClear?: boolean) {
      this.$emit('changeFieldValue', {
        field: this.currentField,
        value,
        ...(isStrongClear && {action: 'STRONG_CLEAR'})
      })
    },
  },
  async mounted() {
    this.vehicleTypes = this.$store.getters.getVehicleCategories
  }
})
