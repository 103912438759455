
import { computed, defineComponent } from 'vue';
import ModalWrapper from '@/components/modals/ModalWrapper.vue';
import { useRoute, useRouter } from 'vue-router';
import vehicles from '@/services/vehicles';
import brand from '@/services/brand';
import { MessageModalMutationTypes } from '@/store/modules/message-modal/mutation-types';
import vehicleBrands from "@/services/vehicleBrands";
import vehicleModels from "@/services/vehicleModels";

export default defineComponent({
  name: 'VehicleLockModal',
  components: { ModalWrapper },
  props: {
    isShowModal: {
      type: Boolean,
      default: () => false
    },
    vehicleInfo: {
      type: String,
      required: true,
      default: () => null,
    },
    checked: {
      type: Array,
      required: true,
      default: () => null,
    },
  },
  methods: {
    successAction() {
      this.$store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'SUCCESS')
      setTimeout(() => {
        this.$emit('success')
      }, 1000)
    },
    closeModal() {
      const element = document.getElementById(`modal-lock-vehicle`)!;
      element.style.display = 'none';
      this.$emit('closeModal');
    },
    async getVehicleBrandsName() {
      await Promise.all(this.checked.map(async (brand: any) => vehicleBrands.findBrandById(brand.id || brand)
        .then((res) => {
          this.vehiclesName.push(res.brand);
        })));
    },
    async getVehicleModelsName() {
      await Promise.all(this.checked.map(async (model: any) => vehicleModels.getModelById(model.id || model)
        .then((res) => {
          this.vehiclesName.push(res.modelFull);
        })));
    },
  },
  data: () => ({
    vehiclesName: [] as any,
    vehicleBrandName: null
  }),
  computed: {
    checkName() {
      if (this.vehicleInfo === 'models') {
        return 'модели'
      }

      if (this.vehicleInfo === 'brands') {
        return 'авто'
      }
    },
    vehicles(): any {
      if (this.vehiclesName.length > 3) {
        const models = this.vehiclesName.slice(0, 3)

        return `${models.join(', ')} (${this.vehiclesName.length - 3} ${this.checkName})`
      }

      return this.vehiclesName.join(', ');
    },
  },
  setup(props) {
    const route = useRoute();
    const queryVehicleState = computed(() => route.query.vehicleState);
    const modalInfo = {
      title: '',
      subTitle: '',
      vehicles: [],
      buttonName: '',
    };

    if (route.query.vehicleState === 'actual') {
      modalInfo.buttonName = 'Заблокировать';

      if (props.vehicleInfo === 'models') {
        vehicleBrands.findBrandById(route.params.id).then((res: any) => {
          modalInfo.title = `Вы действительно хотите заблокировать эти модели ${res.brand}?`;
        })
        modalInfo.subTitle = 'Выбранные модели марки не будут отображаться в справочнике актуальных автомобилей и применяемости артикулов.';
      }

      if (props.vehicleInfo === 'brands') {
        modalInfo.title = 'Вы действительно хотите заблокировать эти марки?';
        modalInfo.subTitle = 'Выбранные марки не будут отображаться в справочнике актуальных автомобилей и применяемости артикулов.';
      }
    }

    if (route.query.vehicleState === 'blocked') {
      modalInfo.buttonName = 'Разблокировать';

      if (props.vehicleInfo === 'models') {
        vehicleBrands.findBrandById(route.params.id).then((res: any) => {
          modalInfo.title = `Вы действительно хотить разблокировать эти модели ${res.brand}?`;
        })
        modalInfo.subTitle = 'Выбранные модели марки станут доступны в справочнике актуальных автомобилей и применяемости артикулов.';
      }

      if (props.vehicleInfo === 'brands') {
        modalInfo.title = 'Вы действительно хотить разблокировать эти марки?';

        modalInfo.subTitle = 'Выбранные марки станут доступны в справочнике актуальных автомобилей и применяемости артикулов.';
      }
    }

    return {
      queryVehicleState,
      modalInfo
    };
  },
  async mounted() {
    if (this.vehicleInfo === 'brands') {
      await this.getVehicleBrandsName();
    }

    if (this.vehicleInfo === 'models') {
      await this.getVehicleModelsName();
    }
  },
});
