
  import ModalWrapper from '@/components/modals/ModalWrapper.vue';
  import { computed, defineComponent, onMounted, ref, watch } from 'vue';
  import MasteringSheet from "@/services/MasteringSheet"
  import prices from "@/services/prices"
  import BaseCheckbox from "@/components/ui/BaseCheckbox.vue";
  import BaseIcon from '@/components/ui/BaseIcon.vue';
  import SearchDropdown from '@/components/search/SearchDropdown.vue';
  import SmallSpinner from '@/components/SmallSpinner.vue';
  import {ConvertDate} from "@/components/converters/date";
  import { GetUserName } from "@/services/authentication";
  import router from "@/router";
  import articles from "@/services/articles";
  import vehicles from "@/services/vehicles";
  import {MessageModalMutationTypes} from "@/store/modules/message-modal/mutation-types";
  import vehicleModels from "@/services/vehicleModels";
  import Trigger from "@/components/dropdown/Trigger.vue";
  import BaseDropdown from "@/components/dropdown/BaseDropdown.vue";
  import DropdownOptions from "@/components/dropdown/DropdownOptions.vue";

  export default defineComponent({
    inheritAttrs: false,
    name: 'ModelCreateModal',
    components: { ModalWrapper, BaseCheckbox, SearchDropdown, SmallSpinner, BaseIcon, Trigger, BaseDropdown, DropdownOptions },
    data: () => ({
      vehicleTypes: [] as any,
      dropdownValue: null as any,
      masteringSheets: null as any,
      selectedMasteringSheet: null as any,
      priceInfo: {} as any,
      activeMenu: 'sheets' as string,
      menu: [
        {key: 'sheets', value: 'Листы освоения'},
        {key: 'lists', value: 'Листы проценки'},
      ] as object,
      listOfArticles: [] as any,
      isLoading: false as boolean,
      searchResult: [] as any,
      showResults: false as boolean,
      error: '' as string,
      newModel: {} as any
    }),
    props: {
      brandName: {
        type: String,
        default: () => null
      }
    },
    computed: {
      modelExample() {
        return {
          modelFull: 'MODEL FULL',
          model: 'MODEL',
          modelConstruction: '200304',
          brandId: null,
          brand: null,
          supplier: {
            id: 1
          },
          constructionYearFrom: '200304',
          constructionYearTo: '200304',
          category: 'PASSENGER',
        }
      },
      getOptionsCategoryModel(): any {
        return this.vehicleTypes.map((type: any) => type.value)
      },
      convertDate() {
        return (date: any) => {
          return ConvertDate(date)
        }
      },
      getUserName() {
        return (id: number) => {
          return GetUserName(id)
        }
      },
      getFieldName() {
        return (key: string) => {
          switch (key) {
            case 'id':
              // return 'ID (' + key + ')'
              return ''
              break;
            case 'supplier':
              // return 'Источник (' + key + ')'
              return ''
              break;
            case 'modelFull':
              return 'Полная модель (' + key + ')'
              break;
            case 'model':
              return 'Модель (' + key + ')'
              break;
            case 'modelConstruction':
              return 'Годы производства (' + key + ')'
              break;
            case 'modCstTermNo':
              // return 'ModCstTermNo (' + key + ')'
              return ''
              break;
            case 'brandId':
              return 'ID бренда (' + key + ') - ' + this.$route.params.id
              break;
            case 'brand':
              return 'Бренд (' + key + ') - ' + this.brandName
              break;
            case 'modelSort':
              // return 'Сортировка (' + key + ')'
              return ''
              break;
            case 'constructionYearFrom':
              return 'Начало производства (' + key + ')'
              break;
            case 'constructionYearTo':
              return 'Окончание производства (' + key + ')'
              break;
            case 'lastUpdate':
              // return 'Дата последнего изменения (' + key + ')'
              return ''
              break;
            case 'category':
              return 'Категория (' + key + ')'
              break;
            case 'tecdocId':
              // return 'Tекдок ID (' + key + ')'
              return ''
              break;
            default:
              break;
          }
        }
      },
      canEdit() {
        return (key: string) => {
          switch (key) {
            case 'modelFull':
              return true
              break;
            case 'model':
              return true
              break;
            case 'modelConstruction':
              return true
              break;
            case 'constructionYearFrom':
              return true
              break;
            case 'constructionYearTo':
              return true
              break;
            case 'category':
              return true
              break;
            default:
              return false
              break;
          }
        }

      }
    },
    methods: {
      setOption(option: any) {
        const categoryOption = this.vehicleTypes.find((type: any) => type.value === option)
        if (categoryOption) {
          this.dropdownValue = option
          this.newModel.category = categoryOption.key.toUpperCase()
          return;
        }


      },
      async setDefaultData() {
        // this.masteringSheets = await MasteringSheet.getSheets();
        // if (this.tablePrice && this.tablePrice.length) {
        //   this.tablePrice.forEach((article: any) => this.listOfArticles.push(article.articleFull))
        // }
      },
      async getPricesForList(id: number) {
        console.log('getPricesForList');
        this.priceInfo = await prices.getPricesForMasteringSheet(id);
        this.$emit('setNewInfo')
      },
      checkItem(itemIndex: number) {
        this.masteringSheets.forEach((ms: any, index: number) => {
          if (index === itemIndex) {
            ms.checked = !ms.checked
            if (ms.checked) this.selectedMasteringSheet = ms
            if (!ms.checked) this.selectedMasteringSheet = null
          } else {
            ms.checked = false
          }
        })
      },
      goToSheet(id: number) {
        this.$router.push(`/price/sheet/${id}`);
        let element = document.getElementById(`modal-price-list`)!;
        element.style.display = 'none'
      },
      goToLists() {
        this.$router.push(`/price/list/${this.listOfArticles.map((a: any) => a.articleId || a.id).join('&')}`);
        let element = document.getElementById(`modal-price-list`)!;
        element.style.display = 'none'
      },
      setActiveMenu(item: any): any {
        this.activeMenu = item.key
      },
      addToList(item: any) {
        if (!!item && !this.listOfArticles.some((article: any) => article.id === item.id)) {
          this.listOfArticles.push(item)
        }
      },
      async search(item: any) {
        // error.value = '';
        this.searchResult = [];
        if (item.target.value.length > 1 && item.target.value.length <= 20) {
          console.log('start search')
          try {
            this.isLoading = true;
            let result = await articles.findArticleByName(item.target.value);
            if (!!result.length) {
              this.searchResult = result.map((o: any) => o.article);
              this.showResults = true;
            }
            if (!result.length) this.error = 'Данные не найдены'
          } catch (error) {
            error.value = 'Ошибка сервера';
            throw error
          }
          this.isLoading = false;
        } else {
          // this.searchResult.value = []
        }
      },
      deleteFromList(index: number) {
        this.listOfArticles.splice(index, 1)
      },
      async addVehicleModel() {
        const brandId = Number(this.$route.params.id)

        const result = {
          tecdocId: 0,
          supplierId: this.newModel.supplier.id,
          modelFull: this.newModel.modelFull,
          model: this.newModel.model,
          modelConstruction: this.newModel.modelConstruction,
          modCstTermNo: 0,
          brandId,
          modelSort: 0,
          constructionYearFrom: Number(this.newModel.constructionYearFrom),
          constructionYearTo: Number(this.newModel.constructionYearTo),
          category: this.newModel.category || 'PASSENGER'
        }

        await vehicleModels.addVehicleModel(result).then();
        let element = document.getElementById(`modal-model-create`)!;
        element.style.display = 'none';
        this.$emit('reload');
        setTimeout(() => {
          this.$store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'SUCCESS')
        }, 100)
      }
    },
    async mounted() {
      this.vehicleTypes = this.$store.getters.getVehicleCategories
      if (this.modelExample) {
        this.newModel = Object.assign({}, this.modelExample);
        this.dropdownValue = this.vehicleTypes.find((type: any) => type.key === this.modelExample.category).value
      }
      this.newModel.modelFull = '';
      this.newModel.model = '';
      this.newModel.modelConstruction = '';
      this.newModel.constructionYearFrom = '';
      this.newModel.constructionYearTo = '';
    }
  })
