
import {defineComponent, computed} from 'vue';
import Spinner from '@/components/Spinner.vue';
import BaseIcon from '@/components/ui/BaseIcon.vue';
import { useRoute } from 'vue-router';
import HeaderVehicleInfo from '@/components/ui/HeaderVehicleInfo.vue';
import BaseCheckbox from '@/components/ui/BaseCheckbox.vue';
import { VehicleMutationTypes } from '@/store/modules/vehicles/mutation-types';
import Breadcrumbs from '@/components/ui/Breadcrumbs.vue';
import ModelEditModal from "@/components/modals/ModelEditModal.vue";
import ModelCreateModal from "@/components/modals/ModelCreateModal.vue";
import {useStore} from '@/store'
import { roles } from '@/services/roles/serviceRoles';
import vehicleBrands from "@/services/vehicleBrands";
import vehicleModels from "@/services/vehicleModels";
import routerService from "@/services/routerService";
import AddVehicleModal from "@/components/modals/AddVehicleModal.vue";
import BaseInput from "@/components/ui/BaseInput.vue";

export default defineComponent({
  name: 'ModelsTable',
  components: {
    Breadcrumbs,
    BaseCheckbox,
    HeaderVehicleInfo,
    Spinner,
    BaseIcon,
    ModelEditModal,
    ModelCreateModal,
    AddVehicleModal,
    BaseInput
  },
  data: () => ({
    isDropdownRevealed: false as boolean,
    vehicleStatePick: 'all' as any,
    pickedModel: {} as any,
    showAll: false as boolean,
    allOnly: false as boolean,
    checked: [] as any,
    isVehicleBlocked: false as boolean,
    isLoading: true as boolean,
    brandModels: [] as any,
    arrSequentialValues: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'А', 'Б', 'В', 'Г', 'Д', 'Е', 'Ё', 'Ж', 'З', 'И', 'Й', 'К', 'Л', 'М', 'Н', 'О', 'П', 'Р', 'С', 'Т', 'У', 'Ф', 'Х', 'Ц', 'Ч', 'Ш', 'Щ', 'Э', 'Ю', 'Я'],
    pickedBrand: null as any,
    modelCategories: {
      moto: 'MOTORBIKE',
      light: 'PASSENGER',
      commerce: 'COMMERCIAL',
      heavy: 'TRUCK_AND_BUS',
      agro: 'TRACTOR_AND_SPECIAL',
      all_categories: ''
    } as any,
    allBrandModels: [] as any
  }),
  watch: {
    '$route.query.vehicleType': function(val) {
      this.checked = [];
      this.setVehicleModels()
    },
    '$route.query.vehicleState': async function() {
      await this.getPickedBrand();
      this.setDefaultModels();
    }
  },
  computed: {
    userViewBlockedUnblockedVehicle(): any {
      return this.$store.getters.getAllPermissions.blocked_vehicles[this.$store.getters.getUserRole]
    },
    userCanAddNewVehicleAndEditVehicle(): boolean {
      return this.$store.getters.getAllPermissions.add_new_vehicles[this.$store.getters.getUserRole]
    },
    getVehicleStateByRouteParam(): any {
      return this.$route.query.vehicleState
    },
    modelPath() {
      return (model: any) => {
        const identicalModels = this.allBrandModels.filter((currentModel: any) => {
          return currentModel.model === model.model
        })

        const modelIds = identicalModels.map((currentModel: any) => currentModel.id)

        let url = `/vehicles?brandId=${model.brand.id}&modelId=${modelIds.join(',')}`

        if (this.$route.query.vehicleState && this.$route.query.vehicleType) {
          url += `&vehicleState=${this.$route.query.vehicleState}&vehicleType=${this.$route.query.vehicleType}`
        }

        if (!this.$route.query.vehicleState && this.$route.query.vehicleType) {
          url += `&vehicleType=${this.$route.query.vehicleType}`
        }

        return url
      }
    },
    getCurrentCrumbs(): any {
      const vehicleTypes = this.$store.getters.getVehicleTypes

      let crumbNameCarsRoute = 'Поиск'
      let crumbNameCarsRouteLink = '/cars'

      const vehicleTypeName: any = vehicleTypes.filter((type: any) => type.key === this.$route.query.vehicleType)
      let vehicleTypeLink: any = `/cars/brands?vehicleType=${this.$route.query.vehicleType}`

      if (this.$route.query.vehicleState) {
        // crumbNameCarsRoute = this.$route.query.vehicleState === 'actual'
        //   ? 'Актуальные'
        //   : 'Заблокированные'

        switch (this.$route.query.vehicleState) {
          case 'actual':
            crumbNameCarsRoute = 'Актуальные'
            break;
          case 'blocked':
            crumbNameCarsRoute = 'Заблокированные'
            break;
          case 'all':
            crumbNameCarsRoute = 'Все'
            break;
        }

        crumbNameCarsRouteLink = `/cars?vehicleState=${this.$route.query.vehicleState}`
        vehicleTypeLink += `&vehicleState=${this.$route.query.vehicleState}`
      }

      if (vehicleTypeName && vehicleTypeName.length) {
        return [
          {
            name: crumbNameCarsRoute,
            link: crumbNameCarsRouteLink
          },
          {
            name: vehicleTypeName[0].value,
            link: vehicleTypeLink
          },
          {
            name: 'Модели',
            link: null
          }
        ]
      } else {
        return [{
          name: 'Бренды',
          link: '/brands'
        },
        {
          name: 'Модели',
          link: ''
        }]
      }
    },
    types(): any {
      return this.$store.getters.getVehicleTypes
    },
    getPath(): any {
      return (type: string) => {
        return routerService.changeQuery('vehicleType', type, this.$route.query, this.$route.path, '')
      }
    },
    checkedAll(): boolean {
      return this.checked.length === this.brandModels.length
    }
  },
  methods: {
    sortedItems(items: any) {
      return items.sort((a: any, b: any) => ('' + a.modelFull).localeCompare(b.modelFull))
    },
    searchModel(value: string) {
      this.brandModels = this.allBrandModels.reduce((acc: any, element: any) => {
        if (acc.findIndex((e: any) => e.model === element.model) < 0) {
          acc.push(element)
        }
        return acc
      }, [])


      this.brandModels = this.brandModels.filter((model: any) => {
        return model.model.toLowerCase().includes(value.toLowerCase())
      })
    },
    openAddVehicleModal() {
      let element = document.getElementById(`modal-vehicle-add`)!
      element.style.display = 'flex'
    },
    closeModalEditModel() {
      this.pickedModel = null
      let element = document.getElementById(`modal-model-edit`)!
      element.style.display = 'none'
    },
    setQuery(query: string) {
      routerService.setQuery('vehicleState', query, this.$route.query, this.$router)
    },
    changeCategoryModels() {
      this.setDefaultModels()
      this.checked = []
    },
    selectAll() {
      if (this.checked.length === this.brandModels.length) {
        this.checked = []
      } else {
        this.checked = [].concat(this.brandModels)
      }
    },
    async getPickedBrand() {
      const response = await vehicleBrands.findBrandById(+this.$route.params.id);
      if (response.brandCustom) {
        this.pickedBrand = `${response.brandCustom}  ${response.brand && response.brand !== response.brandCustom ? '(' + response.brand + ')' : ''}`
      } else {
        this.pickedBrand = response.brand
      }
    },
    goToBack() {
      this.$router.go(-1)
    },
    setChecked(model: any) {
      let index = this.checked.findIndex((o: any) => o.id === model.id);
      if (index !== -1) {
        this.checked.splice(index, 1)
      } else {
        this.checked.push(model)
      }
    },
    changeBlockStatus() {
      if (this.checkQuery && String(this.checkQuery) === 'actual') {
        this.setBlockedModel()
      } else if (this.checkQuery && String(this.checkQuery) === 'blocked')
        this.setUnblockedModel()
    },
    async setLockModel(lock: string) {
      await Promise.all(this.checked.map(async (model: any) => {
        if(lock === 'blocked') {
          await vehicleModels.setBlockedModel(model.id)
        } else if (lock === 'unblocked') {
          await vehicleModels.setUnblockedModel(model.id)
        }
      }));
    },
    async setBlockedModel() {
      if (this.checked.length) {
        this.isLoading = true
        await this.setLockModel('blocked')
        this.setVehicleModels()
        this.checked = []
      }
    },
    async setUnblockedModel() {
      if (this.checked.length) {
        this.isLoading = true
        await this.setLockModel('unblocked')
        this.setVehicleModels()
        this.checked = []
      }
    },
    async setVehicleModels() {
      this.checked = []
      this.isLoading = true

      if (this.$route.query?.vehicleState === 'all' && this.$route.query?.vehicleType) {
        const vehicleTypeByParam = this.$route.query.vehicleType as any
        const vehicleTypes = {
          'agro': 'TRACTOR_AND_SPECIAL',
          'heavy': 'TRUCK_AND_BUS',
          'commerce': 'COMMERCIAL',
          'light': 'PASSENGER',
          'moto': 'MOTORBIKE',
        } as any

        vehicleModels.getModelsByBrandIdAndBlocking(`${this.$route.params.id}`, 'ALL', vehicleTypes[vehicleTypeByParam])
            .then(res => {
              if (res.length < 80) {
                this.showAll = true
                this.allOnly = true
              }
              const sortedModels = this.sortedItems(res)

              this.brandModels = sortedModels.reduce((acc: any, element: any) => {
                if (acc.findIndex((e: any) => e.model === element.model) < 0) {
                  acc.push(element)
                }
                return acc
              }, [])

              this.allBrandModels = sortedModels;
              this.isLoading = false
            })
      } else if (this.$route.query?.vehicleState !== 'all') {
        switch (this.$route.query.vehicleState) {
          case 'actual':
            this.$store.commit(VehicleMutationTypes.SET_VEHICLES_BLOCKED, false)
            this.isVehicleBlocked = false
            break;
          case 'blocked':
            this.$store.commit(VehicleMutationTypes.SET_VEHICLES_BLOCKED, true)
            this.isVehicleBlocked = true
            break;
          default:
            this.$store.commit(VehicleMutationTypes.SET_VEHICLES_BLOCKED, false)
        }

        let modelType = ''
        if (this.$route.query.vehicleType && this.$route.query.vehicleType.length) {
          modelType = this.modelCategories[String(this.$route.query.vehicleType)]
        }
        vehicleModels.getModelsByBrandIdAndTypeAndBlocking(`${this.$route.params.id}`, this.isVehicleBlocked, modelType)
          .then(res => {
            const sortedModels = this.sortedItems(res)

            if (res.length < 80) {
              this.showAll = true
              this.allOnly = true
            }

            this.brandModels = sortedModels.reduce((acc: any, element: any) => {
              if (acc.findIndex((e: any) => e.model === element.model) < 0) {
                acc.push(element)
              }
              return acc
            }, []);


            this.allBrandModels = sortedModels;
            this.isLoading = false
          })
      }
    },
    openEditModal(model: any) {
      this.pickedModel = model
      setTimeout(() => {
        let element = document.getElementById(`modal-model-edit`)!
        element.style.display = 'flex'
      }, 0)
    },
    openAddModelModal() {
      let element = document.getElementById(`modal-model-create`)!;
      element.style.display = 'flex'
    },
    setDefaultModels() {
      this.isVehicleBlocked = this.$store.getters.getVehicleBlocked
      this.setVehicleModels()
    }
  },
  async mounted() {
    await this.getPickedBrand();
    this.setDefaultModels();
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const checkQuery = computed(() => {
      return store.getters.getAllPermissions.blocked_vehicles[store.getters.getUserRole]
          ? route.query.vehicleState === 'all'
              ? false
              : route.query.vehicleState
          : false
    });

    return {
      checkQuery
    }
  },
})
