
  import ModalWrapper from '@/components/modals/ModalWrapper.vue';
  import { computed, defineComponent, onMounted, ref, watch } from 'vue';
  import MasteringSheet from "@/services/MasteringSheet"
  import prices from "@/services/prices"
  import BaseCheckbox from "@/components/ui/BaseCheckbox.vue";
  import BaseIcon from '@/components/ui/BaseIcon.vue';
  import SearchDropdown from '@/components/search/SearchDropdown.vue';
  import SmallSpinner from '@/components/SmallSpinner.vue';
  import {ConvertDate} from "@/components/converters/date";
  import { GetUserName } from "@/services/authentication";
  import router from "@/router";
  import articles from "@/services/articles";
  import ModelEditItem from "@/components/models/ModelEditItem.vue";
  import vehicles from "@/services/vehicles";
  import Spinner from "@/components/Spinner.vue";
  import BaseError from "@/components/ui/BaseError.vue";
  import vehicleModels from "@/services/vehicleModels";
  import ozonImages from '@/services/ozonImages';
  import DropdownActions from '@/components/article-card/history/DropdownActions.vue';

  export default defineComponent({
    inheritAttrs: false,
    name: 'ModelEditModal',
    components: {
      ModalWrapper,
      BaseCheckbox,
      SearchDropdown,
      SmallSpinner,
      BaseIcon,
      ModelEditItem,
      Spinner,
      BaseError,
      DropdownActions
    },
    data: () => ({
      errors: [] as any,
      newModel: null as any,
      edit: false,
      masteringSheets: null as any,
      selectedMasteringSheet: null as any,
      priceInfo: {} as any,
      activeMenu: 'sheets' as string,
      menu: [
        {key: 'sheets', value: 'Листы освоения'},
        {key: 'lists', value: 'Листы проценки'},
      ] as object,
      listOfArticles: [] as any,
      isLoading: false as boolean,
      searchResult: [] as any,
      showResults: false as boolean,
      error: '' as string,
      vehicleInfoChanges: {
        modelConstruction: null as any,
        modelFull: null as any,
        model: null as any,
        constructionYearFrom: null as any,
        constructionYearTo: null as any,
        category: null as any,
      } as any
    }),
    props: {
      models: {
        type: Array,
        required: false,
        default: () => null
      },
      model: {
        type: Object,
        default: () => null
      }
    },
    computed: {
      isShowWarningError(): any {
        return this.vehicleInfoChanges.constructionYearFrom || this.vehicleInfoChanges.constructionYearTo
      },
      convertDate() {
        return (date: any) => {
          return ConvertDate(date)
        }
      },
      getUserName() {
        return (id: number) => {
          return GetUserName(id)
        }
      },
      getFieldName(): any {
        return (key: string) => {
          switch (key) {
            case 'id':
              return 'ID'
              break;
            case 'model':
              return 'Модель'
            case 'supplier':
              // return 'Источник (' + key + ')'
              return ''
              break;
            case 'modelConstruction':
              return 'Годы производства'
              break;
            case 'constructionYearFrom':
              return 'Начало производства'
              break;
            case 'constructionYearTo':
              return 'Окончание производства'
              break;
            case 'modCstTermNo':
              // return 'ModCstTermNo (' + key + ')'
              return ''
              break;
            case 'brandId':
              return 'ID бренда'
              break;
            case 'brand':
              return 'Бренд - ' + this.model.brand.brand
              break;
            case 'lastUpdate':
              return 'Дата последнего изменения'
              break;
            case 'category':
              return 'Категория'
              break;
            case 'tecdocId':
              return 'Tекдок ID'
              break;
            default:
              break;
          }
        }
      },
      canEdit() {
        return (key: string) => {
          switch (key) {
            case 'modelFull':
              return true
              break;
            case 'model':
              return true
              break;
            case 'constructionYearFrom':
              return true
              break;
            case 'constructionYearTo':
              return true
              break;
            case 'category':
              return true
              break;
            default:
              return false
              break;
          }
        }

      },
      checkValidValue(): any {
        return (value: any) => !!(value && value.trim().length);
      },
      getIdenticalModels(): any {
        return this.models.filter((currentModel: any) => {
          return currentModel.model === this.model.model && currentModel.id !== this.model.id
        })
      }
    },
    methods: {
      setModel({title}: any) {
        const selectedModel = title

        this.newModel = {
          ...this.newModel,
          model: selectedModel.model,
          id: selectedModel.id
        }
      },
      async setDefaultData() {
        // this.masteringSheets = await MasteringSheet.getSheets();
        // if (this.tablePrice && this.tablePrice.length) {
        //   this.tablePrice.forEach((article: any) => this.listOfArticles.push(article.articleFull))
        // }
      },
      async getPricesForList(id: number) {
        console.log('getPricesForList');
        this.priceInfo = await prices.getPricesForMasteringSheet(id);
        console.log(this.priceInfo);
        this.$emit('setNewInfo')
      },
      checkItem(itemIndex: number) {
        this.masteringSheets.forEach((ms: any, index: number) => {
          if (index === itemIndex) {
            ms.checked = !ms.checked
            if (ms.checked) this.selectedMasteringSheet = ms
            if (!ms.checked) this.selectedMasteringSheet = null
          } else {
            ms.checked = false
          }
        })
      },
      goToSheet(id: number) {
        this.$router.push(`/price/sheet/${id}`);
        let element = document.getElementById(`modal-price-list`)!;
        element.style.display = 'none'
      },
      goToLists() {
        this.$router.push(`/price/list/${this.listOfArticles.map((a: any) => a.articleId || a.id).join('&')}`);
        let element = document.getElementById(`modal-price-list`)!;
        element.style.display = 'none'
      },
      setActiveMenu(item: any): any {
        this.activeMenu = item.key
      },
      addToList(item: any) {
        if (!!item && !this.listOfArticles.some((article: any) => article.id === item.id)) {
          this.listOfArticles.push(item)
        }
      },
      async search(item: any) {
        // error.value = '';
        this.searchResult = [];
        if (item.target.value.length > 1 && item.target.value.length <= 20) {
          console.log('start search')
          try {
            this.isLoading = true;
            let result = await articles.findArticleByName(item.target.value);
            if (!!result.length) {
              this.searchResult = result.map((o: any) => o.article);
              this.showResults = true;
            }
            if (!result.length) this.error = 'Данные не найдены'
          } catch (error) {
            error.value = 'Ошибка сервера';
            throw error
          }
          this.isLoading = false;
        } else {
          // this.searchResult.value = []
        }
      },
      deleteFromList(index: number) {
        this.listOfArticles.splice(index, 1)
      },
      changeFieldValue(dataField: any) {
        const {value, field} = dataField

        if (field === 'constructionYearFrom') {
          if (dataField.action === 'STRONG_CLEAR') {
            this.newModel.constructionYearFrom = this.model.constructionYearFrom
            this.vehicleInfoChanges.constructionYearFrom = null
            this.vehicleInfoChanges.modelConstruction = null
            this.newModel.modelConstruction = this.model.modelConstruction
            return;
          }

          const modelConstructionValue = this.vehicleInfoChanges.constructionYearTo
            ? `${value} - ${this.vehicleInfoChanges.constructionYearTo}`
            : `${value} -`

          this.vehicleInfoChanges.modelConstruction = modelConstructionValue

          this.newModel = {
            ...this.newModel,
            constructionYearFrom: value,
            modelConstruction: modelConstructionValue
          }
        }

        if (field === 'constructionYearTo') {
          if (dataField.action === 'STRONG_CLEAR') {
            this.newModel.constructionYearTo = this.model.constructionYearTo
            this.vehicleInfoChanges.constructionYearTo = null
            this.vehicleInfoChanges.modelConstruction = null
            this.newModel.modelConstruction = this.model.modelConstruction
            return;
          }

          const modelConstructionValue = this.vehicleInfoChanges.constructionYearFrom
            ? `${this.newModel.constructionYearFrom} - ${value}`
            : value

          this.vehicleInfoChanges.modelConstruction = modelConstructionValue

          this.newModel = {
            ...this.newModel,
            constructionYearTo: value,
            modelConstruction: modelConstructionValue
          }
        }

        this.vehicleInfoChanges[field] = value
      },

      async saveChanges() {
        this.errors = []
        if (!Number.isInteger(+this.vehicleInfoChanges.constructionYearFrom)) {
          this.errors.push('Поле "Начало производства (constructionYearFrom)" может быть только числом')
        }

        if (!Number.isInteger(+this.vehicleInfoChanges.constructionYearTo)) {
          this.errors.push('Поле "Окончание производства (constructionYearTo)" может быть только числом')
        }

        if (this.errors.length) {
          return
        }

        this.isLoading = true



        await Promise.all(this.getIdenticalModels.map(async (model: any) => {
          const payload = {
            ...model,
            modelConstruction: this.checkValidValue(this.vehicleInfoChanges.modelConstruction) ? this.vehicleInfoChanges.modelConstruction : model.modelConstruction,
            model: this.checkValidValue(this.vehicleInfoChanges.model) ? this.vehicleInfoChanges.model : model.model,
            constructionYearFrom: this.checkValidValue(this.vehicleInfoChanges.constructionYearFrom) ? this.vehicleInfoChanges.constructionYearFrom : model.constructionYearFrom,
            constructionYearTo: this.checkValidValue(this.vehicleInfoChanges.constructionYearTo) ? this.vehicleInfoChanges.constructionYearTo : model.constructionYearTo,
            category: this.checkValidValue(this.vehicleInfoChanges.category) ? this.vehicleInfoChanges.category : model.category,
          }

          await vehicleModels.updateVehicleModel(model.id, payload)
        }))

        const payload = {
          ...this.model,
          modelConstruction: this.newModel.modelConstruction,
          model: this.checkValidValue(this.vehicleInfoChanges.model) ? this.vehicleInfoChanges.model : this.model.model,
          constructionYearFrom: this.checkValidValue(this.vehicleInfoChanges.constructionYearFrom) ? this.vehicleInfoChanges.constructionYearFrom : this.model.constructionYearFrom,
          constructionYearTo: this.checkValidValue(this.vehicleInfoChanges.constructionYearTo) ? this.vehicleInfoChanges.constructionYearTo : this.model.constructionYearTo,
          category: this.checkValidValue(this.vehicleInfoChanges.category) ? this.vehicleInfoChanges.category : this.model.category,
        }

        await vehicleModels.updateVehicleModel(this.model.id, payload).then((response: any) => {
          this.newModel = response
          this.$emit('refreshModels')
          this.$emit('closeModal')
          this.isLoading = false
        })
      }
    },
    watch: {
      model() {
        console.log('New model', this.model)
        this.newModel = this.model
        // this.vehicleInfoChanges.modelConstruction = 123
      }
    },
    async mounted() {
      // if (this.tablePrice && this.tablePrice) {
      //   this.activeMenu = 'lists'
      // }
      // await this.setDefaultData()
    }
  })
